.intimation_sec2 {
  padding: 6.25em 0 6.875em;
  .invest_row {
    margin: 0 auto;
    .invest_col {
      padding: 0;
      &:nth-child(2n + 1) {
        padding-right: 0.3125em;
      }
      &:nth-child(2n + 2) {
        padding-left: 3.8125em;
      }
      .heading {
        color: #012b5b;
        font-size: 2.5em;
        font-family: "National-Medium";
        letter-spacing: 0px;
        line-height: 1.2;
        margin-bottom: 1em;
      }
    }
    .report_row {
      margin: 0 auto;
      margin-bottom: 2.5em;
      &:last-child {
        margin-bottom: 0;
      }
      .report_col {
        padding: 0;
        &:nth-child(1) {
          .year {
            padding-top: 0;
          }
        }
      }
      .report_tab_name {
        font-size: 2.5em;
        font-family: "National-Normal";
        text-align: center;
        color: #000000;
        letter-spacing: 0px;
        line-height: 1.2;
        border: 1px solid #012b5b;
        border-bottom: 0.25em solid #012b5b;
        padding: 0.6125em 0.25em;
        cursor: pointer;
        transition: 0.5s ease;
        &.active {
          background-color: #f3f6fa;
          border: 1px solid #f3f6fa;
          border-bottom: 0.25em solid #012b5b;
          transition: 0.5s ease;
        }
      }
      .year {
        font-size: 2.5em;
        font-family: "National-Medium";
        color: #012b5b;
        letter-spacing: 0px;
        line-height: 1.2;
        border-bottom: 1px solid rgba($color: #707070, $alpha: 0.5);
        padding: 1em 0 0.75em;
        margin-bottom: 0.75em;
      }
    }
  }
}

@media (max-width: 991px) {
  .intimation_sec2 {
    .invest_row {
      .invest_col {
        &:nth-child(2n + 2) {
          padding-left: 1.5em;
        }
        .heading {
          font-size: 2.2em;
        }
      }
      .report_row {
        .year {
          font-size: 2.2em;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .intimation_sec2 {
    padding: 6em 0 6em;
    .invest_row {
      .invest_col {
        &:nth-child(2n + 1) {
          padding-right: 0;
        }
        &:nth-child(2n + 2) {
          padding-left: 0;
        }
        .heading {
          font-size: 2.4em;
          letter-spacing: 0px;
          line-height: 1.417;
          margin: 0.8334em 0;
        }
        .invest_tab_wrapper_2 {
          margin-top: 4em;
        }
      }
    }
  }
}
