/* Importing Common CSS File */
@import url("../../common.css");
.our_setup_quality_and_rnd {
  padding: 2em 0 25em;
  margin-top: 8.8em;
  position: relative;
  background-color: #f0f6fd;
}

.our_setup_quality_and_rnd .left_section {
  max-width: 50%;
}
.our_setup_quality_and_rnd .left_section h3 {
  margin-top: 0.5em;
}

.our_setup_quality_and_rnd .left_section p {
  max-width: 80%;
  margin-top: 1.1875em;
}

.our_setup_quality_and_rnd .right_section .bg_img {
  max-width: 41.0625em;
  position: absolute;
  top: 0;
  right: 0;
}

.our_setup_quality_and_rnd .right_section .fg_img {
  /* max-width: 47.4375em; */
  position: absolute;
  bottom: 0;
  right: 0%;
  /* transform: translateY(-30%); */
}

.our_quality {
  padding: 9.0625em 0;
}
.our_quality .left_section {
  max-width: 53.25em;
}
.our_quality ul {
  margin-top: 5.955em;
}
.our_quality ul li p {
  margin-top: 1.2em;
  width: 100%;
  max-width: 60%;
}
.our_quality .our_quality_slider_heading_section {
  display: flex;
  justify-content: space-between;
}

.our_quality_slider_heading_section .navigations {
  margin-top: 3em;
  display: none;
}
.our_quality_slider_heading_section .navigations button {
  color: #1e5093 !important;
}
.our_quality_slider_heading_section .navigations .left_arrow:after {
  border: 2px dashed #1e5093;
}
.our_quality_slider_heading_section .navigations .right_arrow::before {
  border: 2px dashed #1e5093;
}

.our_quality ul figure {
  /* max-width: 4.3125em; */
  max-width: 25%;
  width: 100%;
}

.our_quality ul span {
  margin-top: 0.4375em;
  display: inline-block;
  font-family: "National-Normal";
  font-size: 2em;
  line-height: 1.875em;
}

.rnd {
  padding: 4.3125em 0 4.2em;
  background-color: #f0f6fd;
}

.rnd .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rnd .left_section {
  position: relative;
}

.rnd .right_section {
  max-width: 39.25em;
  margin-top: 10em;
}
.rnd .right_section ul li {
  position: relative;
  display: block;
  padding-left: 1em;
}
.rnd .right_section ul li p::before {
  content: "";
  position: absolute;
  top: 0.7em;
  left: 0.1em;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #002b5a;
}
.rnd .left_section .foreground .fg_image {
  max-width: 50.8125em;
  width: 100%;
  margin-top: 0;
}

.rnd .left_section .bg_image {
  max-width: 10.75em;
  width: 100%;
  position: absolute;
  top: 50%;
}

.rnd .left_section .foreground {
  margin-left: 5.375em;
  position: relative;
  z-index: 1;
}

.rnd .right_section_p {
  margin-top: 1.6875em;
}
.our_quality_slider_navigations_mobile {
  display: none;
}
/* .rnd .right_section ul { */
  /* margin-left: 1em; */
/* } */
.rnd .right_section li {
  display: list-item;
  list-style-type: disc;
}
.right_section_p_mobile {
  display: none;
}
.quality {
  margin-top: 0.5em;
  font-family: "National-Medium";
font-size: 2.28em;
line-height: 1.2em;
}

@media (max-width: 1780px) {
  .our_setup_quality_and_rnd .right_section .fg_img {
    max-width: 56.4375em;
  }
  .rnd .right_section ul li p::before {
    top: 0.65em;
    width: 5px;
    height: 5px;
  }
}
@media (max-width: 1480px) {
  .navigations .left_arrow::after {
    top: 0.3em;
  }
  .our_setup_quality_and_rnd .right_section .fg_img {
    max-width: 57.4375em;
  }
  .rnd .right_section ul li p::before {
    top: 0.7em;
  }
}

@media (max-width: 1367px) {
  .our_quality_slider_wrapper .navigations .right_arrow::before {
    top: 0.2em;
    left: -1.5em;
  }
  .navigations .left_arrow::after {
    top: 0.2em;
  }
  .rnd {
    padding: 4.3125em 0 4.5em;
  }
  .rnd .right_section ul li p::before {
    top: 0.6em;
  }
}

@media (max-width: 1280px) {
  .our_quality_slider_wrapper .navigations button:last-child {
    margin-left: 4em;
  }
  .our_quality_slider_wrapper .navigations .right_arrow::before {
    top: -0.1em;
    left: -1.7em;
  }
  .our_quality_slider_wrapper .navigations .left_arrow::after {
    top: -0.1em;
  }
}

@media (max-width: 1024px) {
  .rnd .left_section .foreground {
    margin-left: 0;
  }
  .rnd .left_section .bg_image {
    left: -5em;
  }
}

@media (max-width: 834px) {
  .our_setup_quality_and_rnd {
    padding-bottom: 60.8em;
    margin-top: 15.8em;
  }
  .our_setup_quality_and_rnd .left_section {
    max-width: 100%;
  }
  .our_setup_quality_and_rnd .right_section {
    margin-top: 5em;
  }
  .our_setup_quality_and_rnd .right_section .bg_img {
    max-width: 50em;
  }
  .our_setup_quality_and_rnd .right_section .fg_img {
    max-width: 65em;
    top: 5em;
    left: 0;
    transform: none;
  }
  .our_quality ul li p {
    max-width: 90%;
  }
  .our_quality {
    padding: 6em 0 5em;
  }
  .our_quality .left_section {
    max-width: 100%;
  }
  .our_quality_slider_navigations_desktop {
    display: none;
  }
  .our_quality_slider_navigations_mobile {
    padding: 0.5em 0;
    margin-top: 3em;
    display: block;
    position: static;
    text-align: center;
    transform: none;
  }
  .our_quality_slider_wrapper .navigations .left_arrow::after {
    display: none;
  }
  .our_quality_slider_navigations_mobile button {
    color: #1e5093 !important;
  }
  .our_quality_slider_navigations_mobile .left_arrow:after {
    border: 2px dashed #1e5093;
  }
  .our_quality_slider_navigations_mobile .right_arrow::before {
    border: 2px dashed #1e5093;
  }

  .rnd .wrapper {
    flex-flow: column;
    align-items: flex-start;
  }
  .rnd .left_section .foreground .fg_image {
    max-width: 100%;
    margin-top: 0;
  }

  .rnd .left_section .foreground {
    margin-left: 0;
    text-align: center;
  }

  .rnd .right_section {
    max-width: 100%;
    margin-top: 9em;
    text-align: left;
  }

  .rnd .right_section p {
    margin-top: 0.5em;
  }
  .rnd .left_section .bg_image {
    top: 60%;
    left: -3em;
  }
  .our_setup_quality_and_rnd .right_section {
    text-align: right;
    margin-right: -12px;
    position: relative;
  }

  .right_section_p_mobile {
    display: block;
  }
  .right_section_p_desktop {
    display: none;
  }
  /* .rnd .right_section ul { */
    /* margin-left: 1.5em; */
  /* } */

  .our_setup_quality_and_rnd .left_section p {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .our_setup_quality_and_rnd {
    padding-bottom: 63em;
    margin-top: 8.8em;
  }
  .rnd .right_section ul li {
    position: relative;
    display: block;
    padding-left: 1em;
  }
  .rnd .right_section ul li p::before {
    content: "";
    position: absolute;
    top: 0.7em;
    left: 0.1em;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #002b5a;
  }
}
@media (max-width: 450px) {
  .our_setup_quality_and_rnd .right_section {
    display: block;
  }
  .our_setup_quality_and_rnd .right_section .bg_img {
    max-width: 20em;
  }
  .our_setup_quality_and_rnd {
    padding-bottom: 34em;
  }
  .rnd {
    padding-top: 2em;
  }
  .rnd .left_section .bg_image {
    max-width: 5em;
    top: 68%;
  }
  .rnd .right_section {
    display: block;
  }
}
