.annual_rep_sec2 {
  padding: 6.25em 0 6.25em;
  .invest_row {
    margin: 0 auto;
    .invest_col {
      padding: 0;
      &:nth-child(2n + 1) {
        padding-right: 0.3125em;
      }
      &:nth-child(2n + 2) {
        padding-left: 3.8125em;
      }
      .heading {
        color: #012b5b;
        font-size: 2.5em;
        font-family: "National-Medium";
        letter-spacing: 0px;
        line-height: 1.2;
        margin-bottom: 1em;
      }
    }
  }
}

@media (max-width: 991px) {
  .annual_rep_sec2 {
    .invest_row {
      .invest_col {
        &:nth-child(2n + 2) {
          padding-left: 1.5em;
        }
        .heading {
          font-size: 2.2em;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .annual_rep_sec2 {
    padding: 6em 0 6em;
    .invest_row {
      .invest_col {
        &:nth-child(2n + 1) {
          padding-right: 0;
        }
        &:nth-child(2n + 2) {
          padding-left: 0;
        }
        .heading {
          font-size: 2.4em;
          letter-spacing: 0px;
          line-height: 1.417;
          margin: 0.8334em 0;
        }
        .invest_tab_wrapper_2 {
          margin-top: 2em;
        }
      }
    }
  }
}
