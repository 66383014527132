.invest_contact_sec2 {
  padding: 6.25em 0 6.25em;
  .invest_row {
    margin: 0 auto;
    .invest_col {
      padding: 0;
      &:nth-child(2n + 1) {
        padding-right: 0.3125em;
      }
      &:nth-child(2n + 2) {
        padding-left: 3.8125em;
      }
      .heading {
        color: #012b5b;
        font-size: 2.5em;
        font-family: "National-Medium";
        letter-spacing: 0px;
        line-height: 1.2;
        margin-bottom: 1em;
      }
      .contact_box {
        .title {
          color: #000000;
          font-size: 2em;
          font-family: "National-Medium";
          letter-spacing: 0px;
          line-height: 1.218;
          margin-bottom: 0.5em;
        }
        .desc {
          color: #000000;
          font-size: 1.5em !important;
          font-family: "National-Normal";
          letter-spacing: 0px;
          line-height: 1.417;
        }
        .map_desc {
          width: 100%;
          max-width: 23.375em;
        }
        .note_desc {
          font-style: italic;
        }
        .contact_data {
          margin-top: 3.75em;
        }
        .data_wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 2.5em;
          &:last-child {
            margin-bottom: 0;
          }
          a {
            color: inherit;
          }
        }
        .loc_wrapper {
          align-items: flex-start;
        }
        .share_icon {
          max-width: 2.375em;
          object-fit: contain;
          margin-right: 0.785em;
        }
        .loc_icon {
          max-width: 1.875em;
          margin-top: 0.5em;
          margin-right: 1.25em;
        }
      }
      .contact_box_1 {
        margin-top: 3.75em;
        .title_1 {
          margin-bottom: 0;
        }
        .contact_data_1 {
          margin-top: 2.125em;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .invest_contact_sec2 {
    .invest_row {
      .invest_col {
        &:nth-child(2n + 2) {
          padding-left: 1.5em;
        }
        .heading {
          font-size: 2.2em;
        }
        .contact_box {
          .title {
            font-size: 1.8em;
          }
          .desc {
            font-size: 1.6em !important;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .invest_contact_sec2 {
    padding: 6em 0 6em;
    .invest_row {
      .invest_col {
        &:nth-child(2n + 1) {
          padding-right: 0;
        }
        &:nth-child(2n + 2) {
          padding-left: 0;
        }
        .heading {
          font-size: 2.4em;
          letter-spacing: 0px;
          line-height: 1.417;
          margin: 1em 0 0.8334em;
        }
        .contact_box {
          .contact_data {
            margin-top: 3em;
          }
          .title {
            font-size: 2em;
            letter-spacing: 0px;
            line-height: 1.5;
            margin-bottom: 0.8em;
          }
          .desc {
            font-size: 1.6em !important;
            letter-spacing: 0px;
            line-height: 1.625;
          }
          .map_desc {
            max-width: 100%;
          }
          .share_icon {
            max-width: 2.22em;
            margin-right: 1.18em;
          }
          .loc_icon {
            max-width: 1.786em;
            margin-right: 1.614em;
          }
        }
        .contact_box_1 {
          margin-top: 4em;
        }
        .invest_tab_wrapper_2 {
          margin-top: 4em;
        }
      }
    }
  }
}
